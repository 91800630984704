<script setup lang="ts">
import { storeToRefs } from 'pinia'

import VText from '~/components/ui/VText.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useSegmentTrackCampaign } from '~/composables/useSegmentTrackCampaign'

import { useUserStore } from '~/stores/user'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import type { PropConfigType } from '~/components/ui/VText.vue'

const segmentTrack = useSegmentTrack()
const { trackCampaignInitiated } = useSegmentTrackCampaign()
const route = useRoute()

const commonTextConfig: PropConfigType = 'sans/14/medium'

const {
  grooviz: GROOVIZ,
  HAS_PAID: USER_HAS_PAID,
  first_payment_date: FIRST_PAYMENT_DATE,
} = storeToRefs(useUserStore())

function trackArtistReferral() {
  segmentTrack('Referral Artist - Page Accessed', {
    accessed_from: 'top navbar',
  })
}

function emitDraftStart() {
  trackCampaignInitiated('header')
  trackEvent(
    {
      category: 'Campaign',
      action: 'Start',
      method: 'Navigation',
      balance: GROOVIZ.value,
      firstPaymentDate: FIRST_PAYMENT_DATE.value,
    },
    route,
  )
}
</script>

<template>
  <div>
    <NuxtLinkLocale
      to="/"
      data-test-id="bandTopNavigationHomeCTA"
      class="sharedNavigationText"
    >
      <VText :cfg="commonTextConfig">
        {{ $t('layout.navigation00') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      to="/draft/"
      class="sharedNavigationText"
      data-test-id="bandTopNavigationStartCampaignCTA"
      @click="emitDraftStart"
    >
      <VText :cfg="commonTextConfig">
        {{ $t('layout.navigation.button.startNewDraft.campaign') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      to="/band/dashboard/"
      data-test-id="bandTopNavigationCampaignsCTA"
      class="sharedNavigationText"
    >
      <VText :cfg="commonTextConfig">
        {{ $t('layout.navigation04') }}
      </VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      v-if="USER_HAS_PAID"
      to="/band/referral/"
      data-test-id="bandTopNavigationReferralCTA"
      @click="trackArtistReferral"
    >
      <i class="fas fa-gift icon lg:!tw-hidden" />
      <span class="sharedNavigationText !tw-hidden lg:!tw-block">
        <VText v-once :cfg="commonTextConfig">
          <span
            class="tw-inline-flex tw-items-center tw-justify-center tw-rounded-xs tw-bg-orange-700 tw-px-2 tw-py-1 tw-text-xs tw-capitalize tw-text-white"
          >
            {{ $t('common.new') }}
          </span>
          <span>{{ $t('layout.navigation13') }}</span>
        </VText>
      </span>
    </NuxtLinkLocale>
  </div>
</template>

<style lang="scss" scoped>
.dashMenu {
  top: calc(100% + 20px);
}

.icon {
  @apply tw-mx-3 tw-h-4;

  @screen lg {
    @apply tw-mx-4;
  }

  @screen xl {
    @apply tw-mx-6;
  }
}

.fas.fa-gift {
  @apply tw-cursor-pointer tw-text-base tw-text-orange-500;

  @keyframes wiggleWaggle {
    from {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: 100ms ease-in-out 4 1500ms reverse both running wiggleWaggle;
  }
}
</style>
