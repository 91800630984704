<script setup lang="ts">
import { storeToRefs } from 'pinia'

import LoggedInBandVue from './LoggedInBand.vue'
import LoggedInCuratorVue from './LoggedInCurator.vue'

import { vClickOutside } from '~/directives/ClickOutside'

import NavContextMenu from '~/components/nav/loggedIn/ContextMenu.vue'
import NotificationBellIcon from '~/components/notifications-v2/bellIcon.vue'
import VText from '~/components/ui/VText.vue'

import { useGetProfilePicture } from '~/composables/useGetProfilePicture'

import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'
import { useUserInfluencerStore } from '~/stores/userInfluencer'

const displayProfileMenu = ref(false)

const { IS_BAND: USER_IS_BAND, IS_INF: USER_IS_INF } =
  storeToRefs(useUserStore())
const { BAND_DATA, id: BAND_ID } = storeToRefs(useUserBandStore())
const {
  entity: INF_NAME,
  id: INFLUENCER_ID,
  has_profile_picture: INF_HAS_PROFILE_PICTURE,
} = storeToRefs(useUserInfluencerStore())

const { imageKey, profilePictureSource } = useGetUserProfilePicture()

const name = computed(() =>
  USER_IS_BAND.value ? BAND_DATA.value?.name : INF_NAME.value,
)

const chevronContext = computed(() =>
  displayProfileMenu.value ? 'up' : 'down',
)

function toggleProfileMenu() {
  displayProfileMenu.value = !displayProfileMenu.value
}

function closeProfileMenu() {
  displayProfileMenu.value = false
}

// inline file composable to group the profile picture logic
function useGetUserProfilePicture() {
  const { timestamp: cacheIdTimestamp } = useGetServerSyncedTimestamp(
    'nav-profile-picture',
  )
  const { getInfProfilePictureForId, getBandProfilePictureForId } =
    useGetProfilePicture()

  const profilePictureSource = computed(() => {
    if (USER_IS_BAND.value) {
      return getBandProfilePictureForId(
        BAND_ID.value,
        {
          size: '40_40',
        },
        cacheIdTimestamp.value,
      )
    }

    return getInfProfilePictureForId(
      INFLUENCER_ID.value,
      {
        size: '40_40',
      },
      cacheIdTimestamp.value,
    )
  })

  const imageKey = computed(() => `${BAND_ID || INFLUENCER_ID || 0}`)

  // the watchers below ensure that the profile picture is refreshed when the user changes it by invalidating the cache
  watch(INF_HAS_PROFILE_PICTURE, (newValue) => {
    if (!newValue) return
    cacheIdTimestamp.value++
  })

  watch(
    () => BAND_DATA.value?.has_profile_picture,
    (newValue) => {
      if (!newValue) return
      cacheIdTimestamp.value++
    },
  )

  return {
    imageKey,
    profilePictureSource,
  }
}
</script>

<template>
  <div class="gridDisplay !tw-gap-4 lg:!tw-gap-6">
    <LoggedInBandVue v-if="USER_IS_BAND" class="gridDisplay" />
    <LoggedInCuratorVue v-if="USER_IS_INF" class="gridDisplay" />

    <NotificationBellIcon ref="notificationBell" />

    <div
      v-click-outside="closeProfileMenu"
      class="tw-flex tw-cursor-pointer tw-items-center"
      data-test-id="bandTopNavigationToggleProfileMenuCTA"
      @click="toggleProfileMenu"
    >
      <transition name="fade" mode="out-in">
        <img
          :key="imageKey"
          class="tw-mr-2 tw-h-8 tw-w-8 tw-flex-shrink-0 tw-cursor-pointer tw-overflow-hidden tw-rounded-full tw-shadow-round"
          :src="profilePictureSource"
          alt=""
        />
      </transition>
      <VText
        cfg="sans/14/medium"
        class="tw-ellipsis tw-mr-2 tw-block tw-max-w-[148px] tw-cursor-pointer"
        >{{ name }}</VText
      >
      <transition name="scale" mode="out-in">
        <i :key="chevronContext" :class="`fas fa-chevron-${chevronContext}`" />
      </transition>
      <transition name="fade">
        <NavContextMenu
          v-if="displayProfileMenu"
          class="profileMenu tw-absolute tw-right-0 tw-z-50 tw-max-w-xs tw-overflow-hidden tw-rounded-xs tw-bg-white tw-shadow-stickyBottom"
          @close="closeProfileMenu"
        />
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gridDisplay {
  @apply tw-relative tw-flex tw-items-center tw-justify-start tw-gap-0;

  @screen lg {
    @apply tw-gap-2;
  }
}

.gridDisplay :deep(.sharedNavigationText) {
  @apply tw-cursor-pointer tw-whitespace-nowrap tw-rounded-xs tw-p-3 tw-transition-colors tw-duration-150 tw-ease-out;

  @screen lg {
    @apply tw-px-4;
  }

  @screen xl {
    @apply tw-px-6;
  }

  &:hover {
    @apply tw-bg-gray-200;
  }
}

.profileMenu {
  @apply tw-w-full tw-max-w-xs;

  top: calc(var(--navbarHeight) - theme('space.4'));
}
</style>
