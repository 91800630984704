<script setup lang="ts">
import { storeToRefs } from 'pinia'

import VText from '~/components/ui/VText.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'

import { useUserStore } from '~/stores/user'

import type { PropConfigType } from '~/components/ui/VText.vue'

const { grooviz: GROOVIZ } = storeToRefs(useUserStore())

const segmentTrack = useSegmentTrack()

const commonTextConfig: PropConfigType = 'sans/14/medium'

function handleTrackReferralAccessSegment() {
  segmentTrack('Referral Curator - Page Accessed', {
    accessed_from: 'top navbar',
  })
}
</script>

<template>
  <div>
    <NuxtLinkLocale
      class="sharedNavigationText"
      to="/influencer/list/all/"
      data-test-id="curatorTopNavigationAllCurators"
    >
      <VText v-once :cfg="commonTextConfig">{{
        $t('layout.navigation12')
      }}</VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      class="sharedNavigationText"
      to="/influencer/dashboard/"
      data-test-id="curatorTopNavigationDashboard"
    >
      <VText v-once :cfg="commonTextConfig">{{
        $t('layout.navigation03')
      }}</VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      class="sharedNavigationText"
      to="/influencer/widget/"
      data-test-id="curatorTopNavigationReceiveMore"
      @click="handleTrackReferralAccessSegment"
    >
      <VText :cfg="commonTextConfig">{{
        $t('components.nav.loggedInCurator.widget')
      }}</VText>
    </NuxtLinkLocale>
    <NuxtLinkLocale
      class="sharedNavigationText"
      to="/influencer/cashout/"
      data-test-id="curatorTopNavigationWallet"
    >
      <VText class="tw-hidden lg:tw-inline-block" :cfg="commonTextConfig">{{
        $t('layout.navigation08', GROOVIZ)
      }}</VText>
      <VText
        v-once
        class="tw-inline-block lg:tw-hidden"
        :cfg="commonTextConfig"
        >{{ $t('layout.countNoNumber') }}</VText
      >
    </NuxtLinkLocale>
  </div>
</template>
