<script setup lang="ts">
import { storeToRefs } from 'pinia'

import DirectionalSwipe from '~/components/transitions/directionalSwipe.vue'
import { Sm500Black } from '~/components/typography'

import { getLocaleFromLanguage, useLanguage } from '~/composables/useLanguage'

import { useUserStore } from '~/stores/user'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { availableLanguages } from '~/types/language'

import type { Language } from '~/types/language'

const { email: USER_EMAIL } = storeToRefs(useUserStore())

const { getCurrentLanguage, setCurrentLanguage } = useLanguage()
const route = useRoute()

const currentLanguage = computed<Language>(() => getCurrentLanguage())

const nextTargetLanguage = computed<Language>(() => {
  const currentIndex = availableLanguages.indexOf(currentLanguage.value)

  return availableLanguages[currentIndex + 1] || availableLanguages[0]
})

const switchLocalePath = useSwitchLocalePath()
const targetLocalePath = computed(() =>
  switchLocalePath(getLocaleFromLanguage(nextTargetLanguage.value)),
)

function switchLanguage(targetLanguage: Language) {
  const originalLanguage = currentLanguage.value

  setCurrentLanguage(targetLanguage, USER_EMAIL.value)
  trackEvent(
    {
      category: 'i18n',
      action: 'Toggle',
      target_lang: targetLanguage,
      origin_lang: originalLanguage,
    },
    route,
  )
}
</script>

<template>
  <NuxtLink
    class="tw-flex tw-cursor-pointer tw-items-center"
    :to="targetLocalePath"
    @click="switchLanguage(nextTargetLanguage)"
  >
    <i class="fas fa-globe-europe tw-mr-1 tw-text-gray-500" />
    <DirectionalSwipe vertical direction="up">
      <Sm500Black :key="currentLanguage" class="tw-uppercase">{{
        $t(`locales.${currentLanguage}`)
      }}</Sm500Black>
    </DirectionalSwipe>
  </NuxtLink>
</template>
