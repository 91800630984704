<template>
  <HeightExpand>
    <article
      class="container tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-50 tw-flex tw-w-[320px] tw-flex-col tw-overflow-hidden tw-rounded-xs tw-bg-white tw-shadow-round sm:tw-absolute sm:tw-bottom-auto sm:tw-left-auto sm:tw-right-8 sm:tw-top-full md:tw-right-0"
      :class="{ mobile }"
    >
      <header
        class="top tw-relative tw-flex tw-items-center tw-justify-between tw-px-5"
        :class="{ 'tw-shadow': mobile }"
      >
        <BtnIcon
          v-if="mobile"
          class="tw-text-gray-500"
          aria-label="Close notifications menu"
          @click="$emit('close')"
        >
          <i aria-hidden class="fas fa-chevron-left tw-text-xs" />
        </BtnIcon>
        <VText
          html-tag="h2"
          class="tw-relative tw-py-4 tw-leading-5"
          :class="{ redDot: hasUnreadNotifs }"
          cfg="sans/16/medium"
          >{{ $t('notifications.notifications') }}</VText
        >
        <BtnIcon
          class="!tw-p-0"
          :disabled="!hasUnreadNotifs"
          @click="handleMarkAllAsReadClick"
        >
          <i
            aria-hidden
            class="fas fa-check-double icon"
            :class="[hasUnreadNotifs ? 'tw-text-gray-500' : 'tw-text-gray-400']"
          />
          <VText
            :class="[
              hasUnreadNotifs ? 'tw-text-gray-500' : 'tw-text-gray-400',
              { 'tw-sr-only': mobile },
            ]"
            class="text"
            cfg="sans/11/medium"
            >{{ $t('notifications.markAllAsRead') }}</VText
          >
        </BtnIcon>
      </header>

      <hr v-if="!mobile" class="tw-border-gray-200" />

      <div
        ref="scrollContainer"
        class="sm-tw-py-4 tw-flex-auto tw-overflow-y-auto tw-p-4 sm:tw-px-3"
        :class="{ 'tw-bg-gray-100': mobile, gradientBar: showGradientBar }"
        @scroll.stop="handleScroll"
      >
        <VDotLoader v-if="loadingInitial" class="tw-my-4" />
        <EmptyState v-else-if="NOTIFICATIONS.length === 0" class="tw-px-2" />
        <NotificationLists
          v-else
          :items="NOTIFICATIONS"
          class="tw-block"
          @read-notification="handleNotificationRead"
        />
        <VDotLoader
          v-if="!loadingInitial && MORE_NOTIFICATIONS_AVAILABLE"
          class="tw-mt-2"
        />
      </div>
    </article>
  </HeightExpand>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import NotificationLists from './NotificationsMenuLists.vue'

import EmptyState from '~/components/notifications-v2/NotificationsEmptyState.vue'
import HeightExpand from '~/components/transitions/heightExpand.vue'
import BtnIcon from '~/components/ui/Buttons/icon.vue'
import VDotLoader from '~/components/ui/Loading/VDotLoader.vue'
import VText from '~/components/ui/VText.vue'

import eventsMixin from '~/mixins/events'

import { useNotificationV2Store } from '~/stores/notificationV2'

import { debounce } from '~/utils'

import type { Notification } from '~/types/notification-v2'

export default defineComponent({
  components: {
    BtnIcon,
    EmptyState,
    HeightExpand,
    NotificationLists,
    VText,
    VDotLoader,
  },
  mixins: [eventsMixin],
  props: {
    mobile: Boolean,
  },
  emits: ['close'],
  data() {
    return {
      loadingInitial: true,
      showGradientBar: false,
    }
  },
  computed: {
    ...mapState(useNotificationV2Store, {
      NOTIFICATIONS: 'all',
      MORE_NOTIFICATIONS_AVAILABLE: 'moreResultsAvailable',
    }),
    // ...mapState({
    //   NOTIFICATIONS: (state: any) => (state as RootState).notificationV2.all,
    //   MORE_NOTIFICATIONS_AVAILABLE: (state: any) =>
    //     (state as RootState).notificationV2.moreResultsAvailable,
    // }),
    hasUnreadNotifs(): boolean {
      return this.NOTIFICATIONS.some(({ dateSeen }) => !dateSeen)
    },
  },
  watch: {
    async loadingInitial(newVal) {
      if (newVal === false) {
        const HEADER_HEIGHT = 52
        const MAX_HEIGHT = 540

        await this.$nextTick()
        const listEl = this.$el.querySelector('ul')
        if (listEl) {
          const listElHeight = listEl.getBoundingClientRect().height
          this.showGradientBar = listElHeight - HEADER_HEIGHT > MAX_HEIGHT
        }
      }
    },
  },
  async created() {
    // Note that we don't unload on menu close so that on next open,
    // the list can be rendered right away (any new items will be visible once fetched)
    await this.FETCH_INITIAL()
    this.loadingInitial = false
  },
  methods: {
    ...mapActions(useNotificationV2Store, {
      FETCH_INITIAL: 'FETCH_INITIAL',
      FETCH_MORE: 'FETCH_MORE',
      READ_NOTIFICATION: 'MARK_READ',
      MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
    }),
    // ...mapActions({
    //   FETCH_INITIAL: 'notificationV2/FETCH_INITIAL',
    //   FETCH_MORE: 'notificationV2/FETCH_MORE',
    //   READ_NOTIFICATION: 'notificationV2/MARK_READ',
    //   MARK_ALL_AS_READ: 'notificationV2/MARK_ALL_AS_READ',
    // }),
    async handleNotificationRead(notification: Notification) {
      await this.READ_NOTIFICATION({ id: notification.id })
      this.$emit('close')
    },
    async handleMarkAllAsReadClick() {
      await this.MARK_ALL_AS_READ()
      this.trackEvent({
        category: 'Notifications V2',
        action: 'Mark all as read',
      })
    },
    handleScroll: debounce(async function (this: any) {
      const container = this.$refs.scrollContainer as Element | undefined
      if (!container || !this.MORE_NOTIFICATIONS_AVAILABLE) return

      const { scrollHeight, scrollTop, clientHeight } = container
      const LOADER_HEIGHT = 16
      const bottomReached =
        scrollTop + clientHeight >= scrollHeight - LOADER_HEIGHT
      if (!bottomReached) return

      await this.FETCH_MORE()
    }),
  },
})
</script>

<style scoped>
.container {
  max-height: min(540px, 100vh - 90px);
  top: calc(var(--navbarHeight) - theme('space.4'));
}

.mobile.container {
  @apply tw-w-screen;

  max-height: unset;
}

.gradientBar::after {
  @apply tw-absolute tw-bottom-0 tw-left-0 tw-h-10 tw-w-full tw-bg-gradient-to-b tw-from-transparent tw-to-white;

  content: '';
}

.redDot::after {
  @apply tw-absolute -tw-right-[10px] tw-h-2 tw-w-2 tw-rounded-full tw-bg-orange-500;

  content: '';
}
</style>
