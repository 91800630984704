<template>
  <component
    :is="containerComponent"
    class="tw-flex tw-items-start tw-p-2 tw-transition-colors tw-duration-150 tw-ease-in-out hover:tw-bg-gray-100"
    :to="notificationLink"
    @click="handleNotificationClick"
  >
    <figure class="tw-relative tw-h-8 tw-w-8 tw-flex-none">
      <div
        class="loader tw-h-8 tw-w-8 tw-overflow-hidden tw-rounded-full tw-shadow-round"
        :class="{ 'tw-opacity-[0.65]': !isUnread }"
      >
        <img
          :src="picture"
          class="tw-w-8 tw-bg-white tw-bg-opacity-0"
          :class="{ '!tw-bg-opacity-100': pictureLoader }"
          alt=""
        />
      </div>
      <span
        class="tw-absolute -tw-bottom-[6px] -tw-right-[6px] tw-overflow-hidden tw-rounded-full tw-bg-white"
      >
        <span
          :class="[notificationBgClass, { 'tw-opacity-[0.65]': !isUnread }]"
          class="tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center"
          aria-hidden
        >
          <i
            class="notificationIcon tw-text-white"
            :class="[notificationIconClass]"
          />
        </span>
      </span>
    </figure>
    <div class="details tw-flex tw-flex-auto tw-flex-col tw-pl-4">
      <VText
        class="tw-leading-4"
        cfg="sans/14/medium"
        :color="isUnread ? 'black' : 'gray-500'"
        >{{ getNotificationTitle() }}</VText
      >
      <VText
        class="tw-mt-[2px]"
        cfg="sans/14/regular"
        :color="isUnread ? 'black' : 'gray-500'"
      >
        <!-- TODO: check i18n-t use -->
        <i18n-t
          tag="span"
          :keypath="`notifications.${item.context.target_kind}.${item.kind}`"
        >
          <template #groovizPerRefferal>
            {{ item.context.groovies_per_referral }}
          </template>
          <template #trackName>
            <VText cfg="sans/14/medium">
              {{ item.context.track_name }}
            </VText>
          </template>
          <template #curatorName>
            <VText cfg="sans/14/medium">
              {{ item.context.influencer_entity }}
            </VText>
          </template>
        </i18n-t>
      </VText>
      <div
        class="tw-mt-2 tw-flex tw-items-center tw-justify-start tw-space-x-1"
      >
        <VText
          html-tag="time"
          cfg="sans/13/regular"
          color="gray-500"
          class="tw-ellipsis tw-block"
          >{{ timeAgo }}</VText
        >
        <VText
          v-if="hasNotificationCtaMessage"
          cfg="sans/13/medium"
          color="gray-500"
          class="tw-block"
          >·</VText
        >
        <VText
          v-if="hasNotificationCtaMessage"
          cfg="sans/13/medium"
          color="orange-500"
          class="tw-block tw-shrink-0"
          >{{ notificationCtaMessage }}</VText
        >
      </div>
    </div>
    <span
      v-if="isUnread"
      class="redDot tw-h-2 tw-w-2 tw-flex-shrink-0 tw-rounded-full tw-bg-orange-500"
      ><span class="tw-sr-only">{{
        $t('notifications.status.unread')
      }}</span></span
    >
    <span v-else class="tw-sr-only">{{ $t('notifications.status.read') }}</span>
  </component>
</template>

<script lang="ts">
import { differenceInSeconds } from 'date-fns'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import VText from '~/components/ui/VText.vue'

import EventsMixin from '~/mixins/events'
import SegmentMixin from '~/mixins/Segment/Segment'

import { useLanguage } from '~/composables/useLanguage'

import { useUserStore } from '~/stores/user'

import pictureBuildHelper from '~/helpers/images/picture_build'

import { formatTimeSince } from '~/utils'

import type { Color } from '~/types/color'
import type { Locale } from '~/types/locale'
import type { Notification, NotificationKind } from '~/types/notification-v2'
import type { PropType } from 'vue'

interface NotificationIcon {
  status: NotificationKind
  class: `fas fa-${string}` | `fa-solid fa-${string}`
  bgClass:
    | `tw-bg-${Color}`
    | `tw-bg-gradient-to-br tw-from-${Color} tw-to-${Color}`
}

const notificationIcons: NotificationIcon[] = [
  {
    status: 'referral_reminder',
    class: 'fa-solid fa-gift',
    bgClass: 'tw-bg-gradient-to-br tw-from-pink-500 tw-to-orange-500',
  },
  {
    status: 'new_feedback',
    class: 'fas fa-envelope',
    bgClass: 'tw-bg-orange-500',
  },
  {
    status: 'shared_track',
    class: 'fas fa-share-nodes',
    bgClass: 'tw-bg-gradient-to-br tw-from-blue-500 tw-to-green-500',
  },
  {
    status: 'share_to_rate',
    class: 'fas fa-share-nodes',
    bgClass: 'tw-bg-gradient-to-br tw-from-blue-500 tw-to-green-500',
  },
  {
    status: 'track_files_are_needed',
    class: 'fas fa-folder-open',
    bgClass: 'tw-bg-blue-500',
  },
  {
    status: 'upload_your_files_reminder',
    class: 'fas fa-folder-open',
    bgClass: 'tw-bg-blue-500',
  },
  {
    status: 'influencer_has_access_to_your_track_files',
    class: 'fas fa-folder-open',
    bgClass: 'tw-bg-blue-500',
  },
  {
    status: 'track_released_after_answer',
    class: 'fas fa-calendar-check',
    bgClass: 'tw-bg-green-500',
  },
  {
    status: 'sharing_pending',
    class: 'fas fa-hourglass-half',
    bgClass: 'tw-bg-red-500',
  },
  {
    status: 'new_submission',
    class: 'fas fa-envelope',
    bgClass: 'tw-bg-orange-500',
  },
  {
    status: 'files_are_available',
    class: 'fas fa-folder-open',
    bgClass: 'tw-bg-blue-500',
  },
  {
    status: 'track_files_changed',
    class: 'fas fa-folder-open',
    bgClass: 'tw-bg-blue-500',
  },
  {
    status: 'some_files_are_already_available',
    class: 'fas fa-folder-open',
    bgClass: 'tw-bg-blue-500',
  },
  {
    status: 'spotify_link_missing',
    class: 'fas fa-link',
    bgClass: 'tw-bg-blue-500',
  },
]

export default defineComponent({
  components: { VText },
  mixins: [EventsMixin, SegmentMixin],
  props: {
    item: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  emits: ['read-notification'],
  setup() {
    const localePath = useLocalePath()
    const { getCurrentLanguage } = useLanguage()

    return {
      localePath,
      getCurrentLanguage,
    }
  },
  data() {
    return {
      notificationIcons,
      pictureLoader: null as null | string,
      pictureLoading: false,
      timeAgo: '',
      timeAgoMutatorInterval: null as null | ReturnType<typeof setInterval>,
    }
  },
  computed: {
    ...mapState(useUserStore, { IS_BAND: 'IS_BAND' }),
    hasNotificationCtaMessage(): boolean {
      const lookupTable: Record<'band' | 'influencer', NotificationKind[]> = {
        band: [
          'track_files_are_needed',
          'upload_your_files_reminder',
          'influencer_has_access_to_your_track_files',
          'referral_reminder',
        ],
        influencer: [
          'files_are_available',
          'new_submission',
          'sharing_pending',
          'track_released_after_answer',
        ],
      }
      const userKind = this.IS_BAND ? 'band' : 'influencer'

      return lookupTable[userKind].includes(this.item.kind)
    },
    notificationCtaMessage(): string | void {
      if (!this.hasNotificationCtaMessage) return

      return this.$t(
        `notifications.ctas.${this.IS_BAND ? 'band' : 'influencer'}.${
          this.item.kind
        }`,
      )
    },
    isUnread(): boolean {
      return this.item.dateSeen === null
    },
    picture(): string {
      return (
        this.pictureLoader ??
        pictureBuildHelper({
          kind: this.IS_BAND ? 'influencer' : 'band',
          target: 'profile_picture',
          size: '40_40',
        })
      )
    },
    notificationIconClass(): NotificationIcon['class'] | '' {
      return (
        this.notificationIcons.find(
          (icon: NotificationIcon) => icon.status === this.item.kind,
        )?.class ?? ''
      )
    },
    notificationBgClass(): NotificationIcon['bgClass'] | '' {
      return (
        this.notificationIcons.find(
          (icon: NotificationIcon) => icon.status === this.item.kind,
        )?.bgClass ?? ''
      )
    },
    containerComponent() {
      return this.item.context.action === 'redirect'
        ? resolveComponent('NuxtLink')
        : 'div'
    },
    notificationLink(): string {
      switch (this.item.kind) {
        case 'spotify_link_missing':
          return this.localePath(
            `/band/edit/track/${
              (
                this.item
                  .context as Notification<'spotify_link_missing'>['context']
              ).track_id
            }/`,
          )
        case 'referral_reminder':
          return this.localePath('/band/referral/')
        default:
          return this.getSubmissionDefaultRedirectionLink()
      }
    },
  },
  created() {
    this.updateTimeAgoValue()
  },
  mounted() {
    const image = new Image()

    image.src = this.item.context.picture
    image.onload = () => {
      this.pictureLoader = image.src
    }
    image.onerror = () => {
      this.pictureLoading = false
    }
    // Less than a minute ago
    if (differenceInSeconds(this.item.dateSent, Date.now()) < 60)
      this.setupSelfDestroyedTimeUpdater()
  },
  beforeUnmount(): void {
    this.destroyTimeAgoMutatorInterval()
  },
  methods: {
    getNotificationTitle() {
      if (['influencer', 'band'].includes(this.item.context.origin_kind)) {
        return this.IS_BAND
          ? this.item.context.influencer_entity || this.item.context.sender
          : this.item.context.band_name || this.item.context.sender
      }

      return this.$t(
        `notifications.titles.${this.item.context.origin_kind}.${this.item.kind}`,
      )
    },
    getSubmissionDefaultRedirectionLink(): string {
      const path = '/band/dashboard/'

      return this.IS_BAND
        ? this.localePath({
            path,
            query: {
              submission: this.item.submission?.toString(),
              refresh: 'true',
            },
          })
        : this.localePath(
            `/influencer/dashboard/?inspect=${this.item.submission}`,
          )
    },
    setupSelfDestroyedTimeUpdater() {
      this.timeAgoMutatorInterval = setInterval(() => {
        if (differenceInSeconds(this.item.dateSent, Date.now()) < 60)
          this.updateTimeAgoValue()
        else this.destroyTimeAgoMutatorInterval()
      }, 5000)
    },
    destroyTimeAgoMutatorInterval() {
      if (this.timeAgoMutatorInterval)
        clearInterval(this.timeAgoMutatorInterval)

      this.timeAgoMutatorInterval = null
    },
    updateTimeAgoValue() {
      this.timeAgo = formatTimeSince(
        this.item.dateSent,
        this.getCurrentLanguage(),
      )
    },
    handleNotificationClick(): void {
      this.$emit('read-notification', this.item)
      this.trackEvent({
        category: 'Notifications V2',
        action: 'Notification clicked',
        value: this.item.submission,
        already_read: !this.isUnread,
      })
      if (this.item.kind === 'referral_reminder') {
        this.trackSegmentEvent('Referral Artist - Page Accessed', {
          accessed_from: 'notification',
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.notificationIcon {
  font-size: 10px;
  line-height: 12px;
}

.loader {
  @apply tw-h-full tw-w-full;

  background: linear-gradient(
    -45deg,
    theme('colors.gray-200'),
    theme('colors.gray-400'),
    theme('colors.gray-300')
  );
  background-size: 400%;
  @media (prefers-reduced-motion: no-preference) {
    animation: gradient 5s ease-in-out infinite;
  }

  &.done {
    animation: unset;
  }
}
</style>
