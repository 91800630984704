<script setup lang="ts">
import { storeToRefs } from 'pinia'

import MismatchPill from './mismatchPill.vue'

import ModalFooter from '~/components/shared/modal/footer.vue'
import { Base400Black, Xs500Gray } from '~/components/typography'
import VCheckbox from '~/components/ui/Inputs/VCheckbox.vue'
import VText from '~/components/ui/VText.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useCuratorMismatches } from '~/composables/useCuratorMismatches'

import { useDraftStore } from '~/stores/draft'
import { useInfluencersDuplicatesStore } from '~/stores/influencersDuplicates'
import { useInfluencersRecommendationsStore } from '~/stores/influencersRecommendations'
import { useMiscResizeStore } from '~/stores/miscResize'

import pictureBuildHelper from '~/helpers/images/picture_build'
import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { isDefined } from '~/utils/type-guards'

import type { SelectionMismatchModalConfig } from '~/stores/miscDraft'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type { FormattedMismatch } from '~/types/influencerRecommendation'

type Props = {
  config: SelectionMismatchModalConfig
}
type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()
const route = useRoute()
const { path: routePath, query: routeQuery } = route
const segmentTrack = useSegmentTrack()
const draftStore = useDraftStore()
const { ADD_INF: ADD_INFLUENCER_TO_CART } = draftStore
const { influencers: CART } = storeToRefs(draftStore)
const { SET_IS_USER_ADDING_ALL_MATCHES } = useMiscDraftStore()
const { isUserAddingAllMatches } = storeToRefs(useMiscDraftStore())
const {
  influencers: DRAFT_CART,
  band: draftBandId,
  id: draftId,
} = storeToRefs(useDraftStore())
const { list: DUPLICATE_SUBMISSIONS } = storeToRefs(
  useInfluencersDuplicatesStore(),
)
const { mismatches: MISMATCHES } = storeToRefs(
  useInfluencersRecommendationsStore(),
)
const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { computeMismatches } = useCuratorMismatches()

const isInDraft = routePath.includes('/draft') || routeQuery?.draftId
const influencersFromConfig = ref<(StatsV3Influencer | Influencer)[]>(
  props.config.influencers.filter(({ id }) =>
    isInDraft ? !DRAFT_CART.value.includes(id) : !CART.value.includes(id),
  ),
)
const selectedInfluencerIds = ref<number[]>(
  influencersFromConfig.value.map(({ id }) => id),
)
const strongMismatches = ref(
  props.config.influencers.reduce<
    {
      influencer: StatsV3Influencer | Influencer
      readonly mismatches: FormattedMismatch[]
    }[]
  >((accumulator, influencer) => {
    const { mismatches } = computeMismatches('popup', influencer)
    if (mismatches.value.some((mismatch) => !mismatch.weakMismatch)) {
      accumulator.push({
        influencer,
        mismatches: toValue(mismatches),
      } as const)
    } else {
      handleInfluencerWithoutStrongMismatch(influencer)
    }
    return accumulator
  }, []),
)

const selectedInfluencersCount = computed<number>(
  () => selectedInfluencerIds.value.length,
)
const nextButtonText = computed(() => {
  if (selectedInfluencersCount.value > 0) {
    const endKey = SCREEN_WIDTH.value < 768 ? 'nextButtonMobile' : 'nextButton'
    return t(
      `components.draft.popup.mismatchModals.selectionMismatchModal.${endKey}`,
      { count: selectedInfluencersCount.value },
    )
  } else {
    return t(
      'components.draft.popup.mismatchModals.selectionMismatchModal.cancelButton',
    )
  }
})

onMounted(() => {
  if (
    influencersFromConfig.value.length === 0 ||
    influencersFromConfig.value.every(({ id }) => {
      return (
        (MISMATCHES.value?.[id]?.every(({ weak }) => weak) ?? true) &&
        !DUPLICATE_SUBMISSIONS.value.includes(id)
      )
    })
  ) {
    ADD_INFLUENCER_TO_CART([...influencersFromConfig.value.map(({ id }) => id)])
    emit('close')
  }
})

function buildInfluencerPicture(
  influencer?: StatsV3Influencer | Influencer,
): string {
  if (isDefined(influencer)) {
    return pictureBuildHelper({
      target: 'profile_picture',
      size: '400_400',
      slug: influencer.has_profile_picture ? influencer.slug : undefined,
      kind: 'influencer',
    })
  } else {
    return pictureBuildHelper({
      target: 'profile_picture',
      size: '400_400',
      slug: undefined,
      kind: 'influencer',
    })
  }
}

function handleCancel(): void {
  trackEvent(
    {
      category: 'Exclusivity',
      action:
        "Choose to not add any influencer from warning popup 'Add them all'",
    },
    route,
  )
  emit('close')
}

function handleNext({ noClose = false, fromClickOutside = false } = {}): void {
  const influencersCount = props.config.influencers.length
  const checkedCount = selectedInfluencerIds.value.length
  const uncheckedCount = influencersCount - selectedInfluencerIds.value.length
  const trustRatio = Math.round((uncheckedCount / influencersCount) * 100) / 100

  if (selectedInfluencerIds.value.length > 0)
    ADD_INFLUENCER_TO_CART(selectedInfluencerIds.value)

  trackEvent(
    {
      category: 'Exclusivity',
      action: "Add influencer batch from warning popup 'Add them all'",
      checkedCount,
      uncheckedCount,
      influencersCount,
      trustRatio,
      fromClickOutside,
    },
    route,
  )

  if (isUserAddingAllMatches.value) {
    segmentTrack('Catalog - Add All to Campaign Clicked', {
      id_band: draftBandId.value,
      id_draft: draftId.value,
      curator_added_to_cart_from: 'mismatch-modal',
      num_curators_selected: selectedInfluencersCount.value,
    })
    SET_IS_USER_ADDING_ALL_MATCHES(false)
  }

  if (!noClose) emit('close')
}

function handleInfluencerWithoutStrongMismatch(
  influencer: Influencer | StatsV3Influencer,
): void {
  influencersFromConfig.value = influencersFromConfig.value.filter(
    ({ id }) => id !== influencer.id,
  )
}

function toggleCheckbox(influencerId: number): void {
  const isInfluencerSelected =
    selectedInfluencerIds.value.includes(influencerId)

  if (isInfluencerSelected) {
    selectedInfluencerIds.value = selectedInfluencerIds.value.filter(
      (id) => id !== influencerId,
    )
  } else {
    selectedInfluencerIds.value.push(influencerId)
  }
}

defineExpose({ handleNext })
</script>

<template>
  <div class="tw-z-50">
    <VText cfg="sans/16/medium" class="tw-mb-2 tw-block">
      {{
        $t(
          'components.draft.popup.mismatchModals.selectionMismatchModal.subtitle',
          { count: selectedInfluencersCount },
        )
      }}
    </VText>
    <Base400Black class="tw-mb-6 tw-block">
      {{
        $t(
          'components.draft.popup.mismatchModals.selectionMismatchModal.description',
        )
      }}
    </Base400Black>
    <div :class="{ 'tw-mb-32': SCREEN_WIDTH < 480 }">
      <div
        v-for="({ influencer, mismatches }, infIndex) in strongMismatches"
        :key="influencer.id"
      >
        <div
          v-if="
            mismatches.some(
              (mismatch: FormattedMismatch) => !mismatch.weakMismatch,
            )
          "
        >
          <div
            v-if="infIndex !== 0"
            class="tw-my-5 tw-h-px tw-w-full tw-bg-gray-300 md:tw-my-6"
          />
          <Xs500Gray class="influencerName tw-mb-2 tw-block">{{
            influencer.entity
          }}</Xs500Gray>
          <div
            class="tw-flex tw-space-x-3"
            :class="{ 'tw-items-center': mismatches.length === 1 }"
          >
            <img
              class="tw-h-6 tw-w-6 tw-rounded-full sm:tw-h-10 sm:tw-w-10"
              :src="buildInfluencerPicture(influencer)"
            />
            <div
              class="tw-flex tw-w-full tw-justify-between tw-space-x-3"
              :class="{ 'tw-items-center': mismatches.length === 1 }"
            >
              <div class="tw-flex tw-flex-col tw-space-y-3">
                <div
                  v-for="(
                    { mismatchText, type, weakMismatch }, index
                  ) in mismatches"
                  :key="index"
                >
                  <MismatchPill
                    :mismatch-text="mismatchText"
                    :is-duplicate="type === 'duplicate'"
                    :is-error="!weakMismatch"
                  />
                </div>
              </div>

              <VCheckbox
                :model-value="selectedInfluencerIds.includes(influencer.id)"
                @update:model-value="toggleCheckbox(influencer.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalFooter
      :has-cancel="selectedInfluencersCount !== 0"
      class="force_float tw-absolute tw-bottom-0 tw-left-0"
      @cancel="handleCancel"
      @next="handleNext"
    >
      <template #cancel>
        {{
          $t(
            'components.draft.popup.mismatchModals.selectionMismatchModal.cancelButton',
          )
        }}
      </template>
      <template #next>
        <span data-test-id="draftPopupSelectionMismatchModalContentConfirmCTA">
          {{ nextButtonText }}
        </span>
      </template>
    </ModalFooter>
  </div>
</template>

<style scoped lang="scss">
.influencerName {
  margin-left: 68px;
}
</style>
