<script setup lang="ts">
import { storeToRefs } from 'pinia'

import MismatchPill from './mismatchPill.vue'

import ModalFooter from '~/components/shared/modal/footer.vue'
import { Base400Black } from '~/components/typography'
import VText from '~/components/ui/VText.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'

import { useDraftStore } from '~/stores/draft'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useRoutingStore } from '~/stores/routing'

import pictureBuildHelper from '~/helpers/images/picture_build'
import { trackEvent } from '~/helpers/LegacyTrackEvent'

import type { MismatchModalConfig } from '~/stores/miscDraft'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type { MismatchType } from '~/types/influencerRecommendation'

type Props = {
  mismatchModalConfig: MismatchModalConfig
}
type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const segmentTrack = useSegmentTrack()
const { band: draftBandId, id: draftId } = storeToRefs(useDraftStore())
const { t } = useI18n()
const route = useRoute()
const { path: routePath } = route
const { computeMismatches } = useCuratorMismatches()
const { mismatches } = computeMismatches(
  'popup',
  props.mismatchModalConfig.influencer,
)
const { ADD_INF } = useDraftStore()
const { SET_IS_USER_ADDING_ALL_MATCHES } = useMiscDraftStore()
const { isUserAddingAllMatches } = storeToRefs(useMiscDraftStore())
const { PREVIOUS: PREVIOUS_URL } = storeToRefs(useRoutingStore())
const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())

onMounted(() => {
  if (!routePath.includes('influencer/profile')) return

  trackEvent(
    {
      category: 'Exclusivity',
      action: 'Try to add an influencer with mismatch, from its profile',
      influencer: {
        entity: props.mismatchModalConfig.influencer.entity,
        id: props.mismatchModalConfig.influencer.id,
      },
      mismatchTypes: mismatches.value.map(({ type }) => type),
      weakMismatch: mismatches.value.every(({ weakMismatch }) => weakMismatch),
    },
    route,
  )
})

const cancelButtonText = computed(() =>
  t('components.draft.popup.mismatchModals.mismatchModal.cancelButton'),
)
const description = computed(() =>
  t('components.draft.popup.mismatchModals.mismatchModal.specificCriteria', {
    influencerName: props.mismatchModalConfig.influencer.entity,
  }),
)
const influencerPicture = computed<string>(() => {
  const { has_profile_picture, slug } = props.mismatchModalConfig.influencer

  return pictureBuildHelper({
    target: 'profile_picture',
    size: '200_200',
    slug: has_profile_picture ? slug : undefined,
    kind: 'influencer',
  })
})

const nextButtonText = computed(() =>
  SCREEN_WIDTH.value < 768
    ? t('components.draft.popup.mismatchModals.mismatchModal.nextButtonMobile')
    : t('components.draft.popup.mismatchModals.mismatchModal.nextButton'),
)

const subtitle = computed(() => {
  const { entity: influencerName } = props.mismatchModalConfig.influencer

  return t('components.draft.popup.mismatchModals.mismatchModal.areYouSure', {
    influencerName,
  })
})

function handleCancel({
  influencer,
  mismatchTypes,
  weakMismatch,
}: {
  influencer: Influencer | StatsV3Influencer
  mismatchTypes: MismatchType[]
  weakMismatch: boolean
}): void {
  if (!influencer) influencer = props.mismatchModalConfig.influencer

  if (mismatchTypes === undefined)
    mismatchTypes = mismatches.value.map(({ type }) => type)

  if (weakMismatch === undefined)
    weakMismatch = mismatches.value.every(({ weakMismatch }) => weakMismatch)

  const { entity, id } = influencer

  trackEvent(
    {
      category: 'Exclusivity',
      action: 'Choose to not add single influencer from warning popup',
      influencer: {
        entity,
        id,
      },
      mismatchTypes,
      weakMismatch,
    },
    route,
  )
  emit('close')
}

function handleNext({
  mismatchTypes,
  noClose = false,
  weakMismatch,
  fromClickOutside = false,
}: {
  mismatchTypes?: MismatchType[]
  noClose?: boolean
  weakMismatch?: boolean
  fromClickOutside?: boolean
}): void {
  if (mismatchTypes === undefined)
    mismatchTypes = mismatches.value.map(({ type }) => type)

  if (weakMismatch === undefined)
    weakMismatch = mismatches.value.every(({ weakMismatch }) => weakMismatch)

  const { entity: influencerEntity, id: influencerId } =
    props.mismatchModalConfig.influencer
  ADD_INF(influencerId)
  trackEvent(
    {
      category: 'Exclusivity',
      action: 'Add single influencer from warning popup',
      influencer: {
        entity: influencerEntity,
        id: influencerId,
      },
      mismatchTypes,
      weakMismatch,
      fromClickOutside,
    },
    route,
  )
  if (PREVIOUS_URL.value.includes('influencer/profile')) {
    trackEvent(
      {
        category: 'Exclusivity',
        action: 'Add influencer with exclusivity mismatch from its profile',
        influencer: {
          entity: influencerEntity,
          id: influencerId,
        },
        mismatchTypes,
        weakMismatch,
      },
      route,
    )
  }

  if (isUserAddingAllMatches.value) {
    segmentTrack('Catalog - Add All to Campaign Clicked', {
      id_band: draftBandId.value,
      id_draft: draftId.value,
      curator_added_to_cart_from: 'mismatch-modal',
      num_curators_selected: mismatches.value.length,
    })
    SET_IS_USER_ADDING_ALL_MATCHES(false)
  }

  if (!noClose) emit('close')
}

defineExpose({
  handleCancel,
  handleNext,
})
</script>

<template>
  <div>
    <VText cfg="sans/16/medium" class="tw-mb-2 tw-block">
      {{ subtitle }}
    </VText>
    <Base400Black class="tw-mb-6 tw-block">
      {{ description }}
    </Base400Black>
    <div class="tw-mb-10 tw-flex tw-items-start tw-space-x-3">
      <img
        class="tw-h-6 tw-w-6 tw-rounded-full sm:tw-h-10 sm:tw-w-10"
        :src="influencerPicture"
      />

      <div class="tw-flex tw-flex-col tw-space-y-3">
        <MismatchPill
          v-for="({ mismatchText, type, weakMismatch }, index) in mismatches"
          :key="index"
          :mismatch-text="mismatchText"
          :is-duplicate="type === 'duplicate'"
          :is-error="!weakMismatch"
        />
      </div>
    </div>
    <ModalFooter
      ref="mismatchModalFooter"
      class="force_float tw-absolute tw-bottom-0 tw-left-0"
      @cancel="
        handleCancel({
          influencer: mismatchModalConfig.influencer,
          mismatchTypes: mismatches.map(({ type }) => type),
          weakMismatch: mismatches.every(({ weakMismatch }) => weakMismatch),
        })
      "
      @next="
        handleNext({
          mismatchTypes: mismatches.map(({ type }) => type),
          weakMismatch: mismatches.every(({ weakMismatch }) => weakMismatch),
        })
      "
    >
      <template #cancel>{{ cancelButtonText }}</template>
      <template #next>
        <span data-test-id="draftPopupMismatchModalContentConfirmCTA">
          {{ nextButtonText }}
        </span>
      </template>
    </ModalFooter>
  </div>
</template>
