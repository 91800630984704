<script setup lang="ts">
import { Sm500Black, Sm600Orange } from '~/components/typography'
import { BtnOrange } from '~/components/ui/Buttons/'
import LanguageSwitcher from '~/components/ui/LanguageSwitcher.vue'

import { useIsOnCmsLandingPage } from '~/composables/useIsOnCMSLandingPage'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useLoginStore } from '~/stores/login'
import { useUserStore } from '~/stores/user'

import { trackEvent } from '~/helpers/LegacyTrackEvent'
import softScrollToDomId from '~/helpers/shared/softScrollToDomId'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const { IS_LOGGED_IN } = storeToRefs(useUserStore())
const { SET_DISPLAY: LOGIN_SET_DISPLAY } = useLoginStore()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

const blogLink = 'https://blog.groover.co'
const route = useRoute()
const { isOnCmsLandingPage } = useIsOnCmsLandingPage()

const isIndex = computed<boolean>(() => {
  return !!(
    route.name?.toString().startsWith('index___') && !IS_LOGGED_IN.value
  )
})

function scrollToDomId(id: string): void {
  softScrollToDomId(id)
}

function handleScroll(domId: string): void {
  scrollToDomId(domId)
  trackEvent(
    {
      category: 'Navbar',
      action: 'Scroll to section',
      target: domId,
    },
    route,
  )
}

function handleBlogClick(): void {
  trackEvent(
    {
      category: 'Navbar',
      action: 'Open blog page',
      link: blogLink,
    },
    route,
  )
}

function handleBecomeAProClick(): void {
  trackEvent(
    {
      category: 'Navbar',
      action: 'Open influencer signup',
    },
    route,
  )
}

function handleSigninClick(): void {
  LOGIN_SET_DISPLAY(true)
  emit('close')
  trackEvent(
    {
      category: 'Navbar',
      action: 'Open sign in dialog',
      label: 'navCTA',
    },
    route,
  )
}

function handleSignupClick(): void {
  BAND_SIGNUP_SET_DISPLAY(true)
  emit('close')
  trackEvent(
    {
      category: 'Navbar',
      action: 'Open sign up dialog',
      label: 'navCTA',
    },
    route,
  )
}
</script>

<template>
  <div class="mainGrid" :class="{ canDisplayRightHandSide: isIndex }">
    <div v-if="isIndex" class="split">
      <Sm500Black @click="handleScroll('mediasAndPros')">{{
        $t('nav.mobile.loggedOut.ourMediasAndPros')
      }}</Sm500Black>
      <Sm500Black @click="handleScroll('howitworks')">{{
        $t('nav.mobile.loggedOut.howItWorks')
      }}</Sm500Black>
      <Sm500Black @click="handleScroll('landingPricing')">{{
        $t('nav.mobile.loggedOut.pricing')
      }}</Sm500Black>
      <a :href="blogLink" target="_blank" @click="handleBlogClick">
        <Sm500Black>{{ $t('nav.mobile.loggedOut.blog') }}</Sm500Black>
      </a>
    </div>
    <div class="split" :class="{ 'tw-ml-auto': !isIndex }">
      <NuxtLinkLocale
        v-if="!isOnCmsLandingPage"
        to="/influencer/signup/"
        @click="handleBecomeAProClick"
      >
        <Sm500Black data-test-id="curatorSignupLink">
          {{ $t('nav.mobile.loggedOut.becomeAPro') }}
        </Sm500Black>
      </NuxtLinkLocale>
      <Sm600Orange data-test-id="loginLink" @click="handleSigninClick">
        {{ $t('common.signIn') }}
      </Sm600Orange>
      <BtnOrange small data-test-id="signupInitCTA" @click="handleSignupClick">
        <span class="btnText">{{ $t('common.signUp') }}</span>
      </BtnOrange>
      <LanguageSwitcher
        v-if="!isOnCmsLandingPage"
        dropdown-position="bottom"
        locale-verbosity="short"
      >
        <template #left-text
          ><i class="fas fa-globe-europe tw-text-gray-500"
        /></template>
      </LanguageSwitcher>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mainGrid {
  @apply tw-grid tw-grid-cols-2 tw-gap-[45px];

  &:not(.canDisplayRightHandSide) {
    @apply tw-grid-cols-1;
  }

  @screen xl {
    @apply tw-flex tw-w-full tw-justify-between;
  }
}

.split {
  @apply tw-grid tw-items-center tw-gap-4 tw-text-center;

  grid-auto-flow: column;

  * {
    @apply tw-cursor-pointer;
  }
}
</style>
